<template>
  <BasePage :isLoaded="!!Content">
    <template v-slot:content>
      <v-container class="products-showcase-container">
        <v-row class="intro-row">
          <v-col cols="12" sm="8" offset-sm="2" align-self="end">
            <MarkdownBlock
              :text="Content.pageTitle"
              :inline="true"
              tag="h1"
              class="h1-big -sci-gradient"
            />
          </v-col>
          <v-col cols="12" sm="4" offset-sm="7" align-self="start">
            <MarkdownBlock
              :text="Content.pageDescription"
              :inline="false"
              tag="div"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" offset-lg="2" class="mt-md-n16 pb-md-10 mr-md-8">
            <v-img
              :lazy-src="getLazySrc(Content.pageImage)"
              :src="optimizedImageURL(Content.pageImage, { width: 600, quality: 95 })"
              width="100%"
              height="auto"
              max-width="300px"
              contain
              class="intro-image first"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" class="mt-md-n16 pb-md-6">
            <v-img
              :lazy-src="getLazySrc(Content.pageImage2)"
              :src="optimizedImageURL(Content.pageImage2, { width: 600, quality: 95 })"
              width="100%"
              height="auto"
              max-width="300px"
              contain
              class="intro-image last"
            />
          </v-col>
        </v-row>
        <ButtonScrollTo
          v-if="!!Content.scrollButtonLabel"
          :label="Content.scrollButtonLabel"
          :smallOnMobile="false"
          target="collections"
          class="invite-to-scroll"
        />
      </v-container>
      <TitleAndText
        :title="Content.topCollectionsTitle"
        :text="Content.topCollectionsDescription"
        id="collections"
        class="top-collections-row"
      />
      <Gallery
        :slides="Content.topCollections"
        :slidesPerView="1.5"
      />
      <div v-if="!!currentList">
        <v-container>
          <v-row align="center" class="our-creations-row">
            <v-col cols="12" sm="8" offset-sm="2" md="4" offset-md="2">
              <h2 class="h2 -sci-gradient">{{ Content.ourCreationsTitle }}</h2>
              <MarkdownBlock
                :text="Content.ourCreationsDescription"
                :inline="false"
                tag="div"
              />
            </v-col>
            <v-col cols="12" sm="8" offset-sm="2" md="3" offset-md="1">
              <ButtonFilters
                @on-filter-click="filterProducts"
                :selectedFilter="categoryIdFilter"
              />
            </v-col>
          </v-row>
        </v-container>
        <CardList
          :cards="currentList"
          @on-reset-filters="filterProducts"
          :filterTitle="categoryTitleFilter"
        />
      </div>
      <v-container>
        <v-row align="center" class="buttons-row">
          <v-col cols="3" offset-sm="1">
            <ButtonScrollTo target="collections" />
          </v-col>
          <v-col cols="6" sm="4">
            <ButtonLoadMore
              v-if="currentPage < pages"
              :label="Content.loadMoreButtonLabel"
              @click="loadMoreData"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </BasePage>
</template>

<script>
import { uniqBy } from 'lodash'
import { getLazySrc, optimizedImageURL } from '@/services/OptimizedImage'
import { MarkdownBlock } from '@monogrid/vue-lib'
import BasePage from '@/organisms/BasePage'
import GetProductShowcaseQuery from '@/graphql/GetProductsShowcase.gql'
import Gallery from '@/molecules/Gallery.vue'
import CardList from '@/molecules/CardList.vue'
import ButtonScrollTo from '@/atoms/ButtonScrollTo.vue'
import ButtonLoadMore from '@/atoms/ButtonLoadMore.vue'
import ButtonFilters from '@/atoms/ButtonFilters.vue'
import TitleAndText from '@/molecules/TitleAndText.vue'

export default {
  name: 'ProductShowcasePage',
  components: {
    MarkdownBlock,
    BasePage,
    Gallery,
    CardList,
    ButtonScrollTo,
    ButtonLoadMore,
    ButtonFilters,
    TitleAndText
  },
  apollo: {
    Content: {
      query: GetProductShowcaseQuery,
      variables: {
        id: '048d9d16-992f-4d81-848d-8a80256faf74'
      },
      async result (res) {
        const products = res.data.Content.topCollections.reduce((accumulator, collection) => {
          accumulator.push(...collection.products)
          return accumulator
        }, []).filter(product => (!!product && !product.isPrivate))
        this.allProducts = uniqBy(products, 'id')
      }
    }
  },
  data: () => ({
    categoryTitleFilter: '',
    categoryIdFilter: -1,
    allProducts: [],
    currentPage: 1,
    limit: 6
  }),
  computed: {
    pages () {
      return Math.ceil(this.filteredList.length / this.limit)
    },
    filteredList () {
      let products = this.allProducts

      if (this.categoryIdFilter !== -1) {
        products = products.filter(product => product.Category.id === this.categoryIdFilter)
      }

      return products
    },
    currentList () {
      return this.filteredList.slice(0, Math.min(this.limit * this.currentPage, this.filteredList.length))
    }
  },
  methods: {
    getLazySrc,
    optimizedImageURL,
    filterProducts (category) {
      if (category) {
        this.categoryIdFilter = category.id
        this.categoryTitleFilter = category.title
      } else {
        this.categoryIdFilter = -1
        this.categoryTitleFilter = 'All'
      }
      this.currentPage = 1
    },
    loadMoreData () {
      this.currentPage = Math.min(this.pages, (this.currentPage + 1))
    }
  },
  metaInfo: function () {
    if (this.Content) {
      let meta = [
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: this.Content.metaTitle },
        { vmid: 'description', name: 'description', content: this.Content.metaDescription },
        { vmid: 'ogdescription', property: 'og:description', content: this.Content.metaDescription }
      ]

      if (this.Content.metaImage) {
        meta = meta.concat([
          { vmid: 'ogimage', property: 'og:image', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + optimizedImageURL(this.Content.metaImage, { width: 1200, height: 625, format: 'jpg', fit: 'crop' }) },
          { vmid: 'ogimagetype', property: 'og:image:type', content: 'image/jpeg' },
          { vmid: 'ogimageheight', property: 'og:image:width', content: '1200' },
          { vmid: 'ogimagewidth', property: 'og:image:height', content: '675' }
        ])
      }

      return {
        title: this.Content.metaTitle,
        meta
      }
    }
  }
}
</script>

<style scoped lang="scss">
.products-showcase-container {
  position: relative;
  height: calc(100vh - 84px);
}

.intro-row {
  height: 100%;

  h1 {
    @include breakpoint ('sm-and-down') {
      padding-bottom: rem(32px);
    }
  }

  p {
    @include breakpoint ('sm-and-down') {
      padding-bottom: rem(125px);
    }
  }

  .intro-image {
    @include breakpoint ('sm-and-down') {
      max-height: rem(250px);
    }

    &.first {
      transform: translateY(rem(-20px));
    }

    &.last {
      transform: translateY(rem(20px));
    }
  }
}

.invite-to-scroll {
  position: absolute;
  bottom: rem(10px);
  right: 50%;
  transform: translateX(50%);

  @include breakpoint ('sm-and-down') {
    bottom: rem(30px);
  }
}

.our-creations-row,
.top-collections-row {
  padding-top: rem(100px);
}

.buttons-row {
  padding: rem(100px) 0;
}
</style>
